<template>
  <div v-padding:top="46">
    <van-nav-bar title="排污许可" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          name="permitCode"
          v-model="formData.permitCode"
          label="许可证编码"
          placeholder="请填写许可证编码"
          :rules="[{ required: true, message: '请填写许可证编码' }]"
        />
        <van-field
          name="issueOrg"
          v-model="formData.issueOrg"
          label="颁发机构"
          placeholder="请填写颁发机构"
          :rules="[{ required: true, message: '请填写颁发机构' }]"
        />
        <van-field
          readonly
          clickable
          name="gmtStart"
          v-model="formData.gmtStart"
          label="有效起始日期"
          placeholder="请选择有效起始日期"
          @click="onCalendarShow('gmtStart')"
          :rules="[{ required: true, message: '请选择有效起始日期' }]"
        />
        <van-field
          readonly
          clickable
          name="gmtEnd"
          v-model="formData.gmtEnd"
          label="有效结束日期"
          placeholder="请选择有效结束日期"
          @click="onCalendarShow('gmtEnd')"
          :rules="[{ required: true, message: '请选择有效结束日期' }]"
        />
        <van-field
          readonly
          clickable
          name="gmtIssue"
          v-model="formData.gmtIssue"
          label="颁发日期"
          placeholder="请选择颁发日期"
          @click="onCalendarShow('gmtIssue')"
          :rules="[{ required: true, message: '请选择颁发日期' }]"
        />
        <van-field
          name="url"
          v-model="formData.url"
          label="公开内容网址"
          placeholder="请填写公开内容网址"
          :rules="[{ required: true, message: '请填写公开内容网址' }]"
        />
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-calendar
      v-model="calendarShow"
      type="single"
      :default-date="defaultDate"
      @confirm="onConfirm"
      :allow-same-day="true"
      :style="{ height: '62%' }"
      color="#378aed"
    />
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        id: "",
        permitCode: "",
        gmtStart: "",
        gmtEnd: "",
        issueOrg: "",
        gmtIssue: "",
        url: "",
      },
      submitLoading: false,
      calendarShow: false,
      defaultDate: this.$moment().toDate(),
      calendarType: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.operate == "edit") {
      this.getBlowoffInfo();
    }
  },
  methods: {
    // 新增排污许可证
    addBlowoff() {
      this.submitLoading = true;
      const data = {
        permitCode: this.formData.permitCode,
        gmtStart:
          this.$moment(this.formData.gmtStart).format("YYYY-MM-DD") +
          " 00:00:00",
        gmtEnd:
          this.$moment(this.formData.gmtEnd).format("YYYY-MM-DD") + " 23:59:59",
        issueOrg: this.formData.issueOrg,
        gmtIssue:
          this.$moment(this.formData.gmtIssue).format("YYYY-MM-DD") +
          " 00:00:00",
        url: this.formData.url,
        enterpriseId: this.enterpriseId,
      };
      this.$api.setting
        .postAddPermit(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 修改排污许可证
    editBlowoff() {
      this.submitLoading = true;
      const data = {
        id: this.formData.id,
        permitCode: this.formData.permitCode,
        gmtStart:
          this.$moment(this.formData.gmtStart).format("YYYY-MM-DD") +
          " 00:00:00",
        gmtEnd:
          this.$moment(this.formData.gmtEnd).format("YYYY-MM-DD") + " 23:59:59",
        issueOrg: this.formData.issueOrg,
        gmtIssue:
          this.$moment(this.formData.gmtIssue).format("YYYY-MM-DD") +
          " 00:00:00",
        url: this.formData.url,
        enterpriseId: this.enterpriseId,
      };
      this.$api.setting
        .putPermit(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    onCalendarShow(type) {
      switch (type) {
        case "gmtStart":
          this.defaultDate = this.formData.gmtStart
            ? this.$moment(this.formData.gmtStart).toDate()
            : this.$moment().toDate();
          break;
        case "gmtEnd":
          this.defaultDate = this.formData.gmtEnd
            ? this.$moment(this.formData.gmtEnd).toDate()
            : this.$moment().toDate();
          break;
        case "gmtIssue":
          this.defaultDate = this.formData.gmtIssue
            ? this.$moment(this.formData.gmtIssue).toDate()
            : this.$moment().toDate();
          break;
      }
      this.calendarType = type;
      this.calendarShow = true;
    },
    onSubmit() {
      if (this.$route.query.operate == "add") {
        this.addBlowoff();
      } else if (this.$route.query.operate == "edit") {
        this.editBlowoff();
      }
    },
    // 日历确定
    onConfirm(date) {
      switch (this.calendarType) {
        case "gmtStart":
          this.formData.gmtStart = this.$moment(date).format("YYYY-MM-DD");
          break;
        case "gmtEnd":
          this.formData.gmtEnd = this.$moment(date).format("YYYY-MM-DD");
          break;
        case "gmtIssue":
          this.formData.gmtIssue = this.$moment(date).format("YYYY-MM-DD");
          break;
      }
      this.calendarShow = false;
    },
    // 获取排污许可证详情
    getBlowoffInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting.getPermitInfo(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = {
            id: res.data.data.id,
            permitCode: res.data.data.permitCode,
            gmtStart: res.data.data.gmtStart
              ? this.$moment(res.data.data.gmtStart).format("YYYY-MM-DD")
              : "",
            gmtEnd: res.data.data.gmtEnd
              ? this.$moment(res.data.data.gmtEnd).format("YYYY-MM-DD")
              : "",
            issueOrg: res.data.data.issueOrg,
            gmtIssue: res.data.data.gmtIssue
              ? this.$moment(res.data.data.gmtIssue).format("YYYY-MM-DD")
              : "",
            url: res.data.data.url,
          };
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
